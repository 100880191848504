:root{
  --purple: #252262;
}

.info-container{
  height: auto;
  width: 100%;
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--purple);
  /* background: url("./interface-images/us-info-image.webp") no-repeat center top / cover; */
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.info-container-background-color{
  background-color: #252262;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.info-container h1{
  text-align: center;
  font-size: 40px;
  color: white;
  margin-bottom: 20px;
  z-index: 1;
}

.info-container-cards{
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1320px) {
  .info-container{
    padding: 40px 0px;
  }
}
