:root{
  --purple: #252262;
  --green: #B8EA14;
}

.experience-container{
  height: 450px;
  width: 85%;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  z-index: 1;
}

.experience-container h4{
  height: auto;
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.experience-years-text{
  font-weight: 600;
}

.info-experience-container{
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: space-around;  
}

.years-experience-container{
  min-height: 250px;
  height: auto;
  width: 514px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}

.years-experience-container > div{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.experience-icon{
  height: 90px;
  width: 90px;
  color: var(--green);
}

.experience-years{
  font-size: 40px;
}

.experience-years::after{
  content: "+";
  color: var(--green);
  margin-left: 5px;  
}

.years-experience-container p{
  font-size: 20px;
  text-align: center;
}

.experience-points{
  height: 100%;
  width: 58%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 18px;
}

.experience-points div{
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
}

.points-icons{
  margin-right: 5px;
  color: var(--green);
}

.experience-description{
  height: 250px;
  width: 514px;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}

.experience-button-container{
  height: auto;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.experience-button-container button{
  height: 52px;
  width: 200px;
  font-size: 17px;
  background-color: var(--green);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.experience-container button:hover{
  scale: 1.1;
}

@media screen and (max-width: 1240px) {
  .experience-container{
    height: auto;
    width: 85%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 500;
    gap: 16px;
  }  

  .info-experience-container{
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .years-experience-container{
    height: 250px;
  }
}

@media screen and (max-width: 530px) {

  .info-experience-container{
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .years-experience-container{
    height: auto !important;
    width: 85vw;
    padding: 10px 10px 15px 10px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .years-experience-container > div{
    height: 47%;
    width: 100%;
  }

  .experience-points{
    height: 47%;
    width: 100%;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .experience-points div{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  
  .points-icons{
    margin-right: 5px;
    color: var(--green);
  }

  .experience-description{
    height: auto;
    width: 85vw;
    padding: 10px;
  }

}


