:root{
  --purple: #252262;
  --green: #B8EA14;
}

.work-team-container{
  min-height: 523px;
  height: auto;
  width: 100%;
  padding: 90px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url("./interface-images/work-team-image1.webp") no-repeat center top / cover; */
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.work-team-background-color{
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: var(--purple);
  z-index: 0;
  opacity: 0.8;
}

.work-team-info{
  height: auto;
  width: 70vw;
  color: white;
  z-index: 1;
}

.work-team-info h3{
  height: 40px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 15px;
}

.work-team-info > p{
  text-align: justify;
  font-size: 18px;
  margin-bottom: 30px;
}

.frase{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.frase-text{
  height: 180px;
  width: 180px;
  border: 5px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}