.services-container{
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
}

.services-info-container{
  width: 85vw;
  display: flex;
  align-items: center;
}

.services-info{
  height: auto;
  width: 40%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
}

.services-info h3{
  height: 40px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 15px;
}

.services-info p{
  text-align: justify;
  font-size: 18px;
}

@media screen and (max-width: 1140px) {
  .services-info-container{
    flex-direction: column;
    width: 90vw;
  }

  .services-info{
    width: 75%;
    margin-right: 0;
  }
}

@media screen and (max-width: 805px) {
  .services-container{
    padding-bottom: 0px;
  }  
}