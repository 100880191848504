.events-container{
  height: auto;  
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 90px 0px 0px 10px;
  /* background-color: #B8EA14; */
}

.events-info{
  height: auto;
  width: 70vw;
  display: flex;
  flex-direction: column;
}

.events-info h3{
  height: 40px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 15px;
}

.events-info p{
  text-align: center;
  font-size: 18px;
}

