.links-container{
  padding: 0 2rem;
  position: relative;
  width: calc(160px + 4 * 70px + 4rem);
  display: flex;
  justify-content: center;
}

.link{
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 50px;
  border-radius: 99em;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transform-origin: center left;
  transition: width 0.2s ease-in;
  text-decoration: none;
  color: inherit;
}

.link::before{
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border-radius: 99em;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  transform-origin: center right;
  background-color: #a3cf12;
}

.link:hover, .link:focus{
  outline: 0;
  width: 150px;
}

.link:hover::before, .link:hover .link-title{
  transform: translateX(0%);
}

.link-icon{
  font-size: 27px !important;
  display: block;
  flex-shrink: 0;
  left: 18px;
  position: absolute;
}

.link-title{
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  display: block;
  text-align: center;
  text-indent: 20px;
  width: 100%;
}

.facebook{
  color: #2374E1;
}

.instagram{
  background: -webkit-linear-gradient(rgba(84,66,213,1), rgba(219,34,101,100), rgba(254,192,83,1));
  -webkit-background-clip: text;
  background-clip: transparent;
  -webkit-text-fill-color: transparent;
  color: tomato;  
}

.youtube{
  color: #FF0000;
}

.linkedin{
  color: #2867B2;
}

@media screen and (max-width: 500px) {
  .links-container{
    width: 100%;
  }

  .link:hover, .link:focus{
    outline: 0;
    width: 70px;
  }

  .link-title{
    display: none;
  }
}


