.footer-container{
  height: auto;
  min-height: 110px;
  width: 100%;
  padding: 20px 0;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #B8EA14;
}

.links_container{
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copyright_text_mobile,
.copyright_text_desktop{
  display: flex;
  width: auto;
  min-width: 130px;
  text-align: center;
  font-weight: 500;
}

.footer-container p{
  height: auto;
  width: 15%;
  font-style: italic;
  color: rgb(51, 51, 51);
}

.footer-container img{
  height: 70px;
  width: auto;
}

.footer-contact-container{
  height: auto;
  width: 500px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer-contact-container a{
  text-decoration: none;
  color: black;
}

@media screen and (min-width: 1011px) {
  .copyright_text_mobile{
    display: none;
  }
}

@media screen and (max-width: 1010px) {
  .copyright_text_desktop{
    display: none;
  }

  .footer-container{
    flex-direction: column;
    gap: 16px;
  }

  .footer-contact-container{
    height: auto;
    width: auto;
    gap: 10px;
  }
}

@media screen and (max-width: 500px) {
  .links_container{
    width: 100%;
  }
}