:root{
  --purple: #252262;
  --green: #B8EA14;
}

.services-splide-container{
  width: 55%;
}

.services-splide1, .services-splide2{
  height: 210px;
  width: 100%;
}

.services-card{
  position: relative;
  margin: 0 10px;
  height: 210px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-card span:nth-child(1){
  position: absolute;
  height: 150px;
  width: 150px;
  border: 2px solid black;
  border-radius: 50%;

}

.card-green:hover span:nth-child(1){
  border: none;
  background: var(--green);
}

.card-purple:hover span:nth-child(1){
  border: none;
  background: var(--purple);
}

.card-purple:hover{
  color: white;
}

.content{
  position: relative;
  padding: 40px 10px;
  text-align: center;
  transition: 0.5s;
  z-index: 1;
}

.services-icon{
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1140px) {
  .services-splide-container{
    width: 75%;
  }
}

@media screen and (max-width: 805px) {
  .services-splide-container{
    display: none;
  }
}
