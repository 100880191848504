:root{
  --purple: #252262;
  --green: #B8EA14;
}

.card-container{
  min-height: 270px;
  height: auto;
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 60px;
  position: relative;
}

.card{
  position: relative;
  height: 270px;
  width: 120px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease;
}

.card-background{
  position: absolute;
  right: 0;
  left: 0;
  padding: 5px;
  font-weight: 500;
  background-size: 350px;
  background-position: center;
  transform: scale(1) translateZ(0);
  transition: .3s ease;
  transform-origin: bottom;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.card-background-up{
  top: 0px;
  bottom: 50px;
  background-color: var(--green);
  color: black;
}

.card-background-down{
  top: 50px;
  bottom: 0px;
  background-color: white;
  color: var(--purple);
}

.card-name{
  position: absolute;
  height: 180px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.icon{
  position: absolute;
  height: 25px;
  width: 25px;
  bottom: 10px;
  left: 10px;
}

.card::before{
  content: "";
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card:hover .card-background{ 
  transform: scale(1.15) translateZ(0);
  background-size: 260px;
}


.card-container:hover, .card:not(:hover){
  transform: scale(.98);
}

.card-container:hover > .card:not(:hover) .card-background,
.card-container:hover > .card:not(:hover) .card-category{
  filter: brightness(.5) saturate(0) contrast(1.2) blur(20px);
}


@media screen and (max-width: 1320px) {
  .card-container{
    flex-wrap: wrap;
    padding: 0px 10px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 450px) {
  .card-container{
    justify-content: end;
    gap: 24px;
    overflow: hidden;
    width: 80%;
  }

  .card{
    height: 100px;
    width: 270px;
    margin-right: 15px;
  }

  .card-background-up{
    top: 0px;
    bottom: 0px;
  }
  
  .card-background-down{
    top: 0px;
    bottom: 0px;
  }

  .card-name{
    height: 90%;
    width: 80%;
    right: 5px;
  }
}