nav{
  height: 80px;
  width: 100vw;
  position: fixed;
  display: flex;
  font-weight: 700;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  transition: 0.6s;
  z-index: 10000;
}

.logo{
  height: 80px;
  width: 111.52px;
  padding: 10px;
  transition: 0.6s;
}

.navbar-list-desktop{
  width: 25vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
  text-align: center;
}

.navbar-list-desktop li, .navbar-list-mobile li{
  list-style: none;
  cursor: pointer;
  color: black;
}

.navbar-list-desktop li:hover{
  color: #B8EA14;
  transition: 0.2s;
}

.navbar-list-mobile{
  height: auto;
  min-height: 200px;
  width: 200px;
  padding: 10px;
  position: absolute;
  gap: 8px;
  top: 60px;
  right: 15px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.navbar-list-mobile li{
  border: 1px solid  #CFD1D6;
  padding: 5PX;
  border-radius: 8PX;
  background-color: #F1FBD1;
}

.burguer-icon{
  height: 35px;
  width: 35px;
  color: #252262;
}

.disable{
  display: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}

@media screen and (max-width: 880px) {

  nav{
    width: 100vw;
    padding: 0px 20px;
    justify-content: space-between;
  }

  .navbar-list-desktop{
    display: none;
  }

}

@media screen and (min-width: 879px) {

  .burguer-icon{
    display: none;
  }

  .navbar-list-mobile{
    display: none;
  }
}








