:root{
  --purple: #252262;
  --green: #B8EA14;
}

.contact-container{
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contact-info{
  min-height: 120px;
  height: auto;
  margin-bottom: 10px;
  width: 70vw;
  display: flex;
  flex-direction: column;
}

.contact-info h3{
  min-height: 40px;
  height: auto;
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 15px;
}

.contact-info p{
  text-align: center;
  font-size: 18px;
}

.contact-logo{
  position: absolute;
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

@media screen and (max-width: 587px) {
  .contact-info{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 530px) {
  .contact-logo{
    height: auto;
    width: 80vw;
  }
}


