.App{
  overflow-x: hidden;
}

.whatsapp-container, .phone-container{
    height: 10vh;
    width: auto;
    position: absolute;
    bottom: 5vh;
    right: 3vw;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
  }

  .whatsapp-container{
    bottom: 5vh;
  }

  .phone-container{
    bottom: 17vh;
  }
   
  .whatsapp-text, .phone-text{
    height: 35px;
    width: 165px;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: none;
  }

  .whatsapp-button, .phone-button{
    height: 55px;
    width: 55px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 25px;
    position: relative;
    transform: all .5s ease;
    border-right: none;
    color: white;
    cursor: pointer;
  }

  .whatsapp-button{
    background-color: #35D751;
  }

  .phone-button{
    background-color: #fbff00;
    color: black;
  }

  .whatsapp-button::before, .phone-button::before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
  }

  .whatsapp-button::before{
    background-color: #35D751;
  }

  .phone-button::before{
    background-color: #252262;
  }

  .whatsapp-button:focus, .phone-button:focus{
    border: none;
    outline: none;
  }
  
  .whatsapp-button:nth-child(1), .phone-button:nth-child(1){
    color: white;
  }
  
  .whatsapp-container:hover > .whatsapp-text, .phone-container:hover > .phone-text{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .disable{
    display: none;
  }

  @keyframes pulse-border{
    0%{transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1); opacity: 1;}
    100%{transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5); opacity: 0;}
  }