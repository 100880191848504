.video-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video{
  position: absolute;
  background-size: cover;
  z-index: -1;
  height: 100%;
}

.banner_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
}

.banner-button{
  height: 52px;
  width: 200px;
  font-size: 17px;
  background-color: var(--green);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.banner-button:hover{
  scale: 1.1;
}

.banner-text{
  width: 470px;
  justify-content: center;
  align-items: center;
  color: white;
}

.banner-text > h2{
  font-size: 70px;
  line-height: 1.3;
  font-weight: lighter;
  text-align: center;
  font-family: 'League Gothic', sans-serif;
}

.banner-text > p{
  text-align: center;
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
}


@media screen and (max-width: 530px) {

  .video-desktop{
    display: none;
  }

  .video-mobile{
    display: block;
  }

  .banner-text{
    width: 90%;
  }
  
  .banner-text > h2{
    font-size: 50px;
  }
  
  .banner-text > p{
    font-size: 20px;
  }
}

@media screen and (min-width: 529px) {

  .video-desktop{
    display: block;
  }

  .video-mobile{
    display: none;
  }
}

