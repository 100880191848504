:root{
  --purple: #252262;
  --green: #B8EA14;
}

.services-card-container{
  height: auto;
  width: 78%;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.services-card-mobile{
  height: 150px;
  width: 125px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  background: rgb(184,234,20);
  background: linear-gradient(140deg, rgba(184,234,20,0.9556197478991597) 0%, rgba(37,34,98,0.9500175070028011) 50%);
}

.services-card-icon{
  height: 35px;
  width: 35px;
  margin-bottom: 5px;
}


@media screen and (max-width: 1320px) {
  .card-container{
    flex-wrap: wrap;
    padding: 0px 10px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 806px) {
  .services-card-container{
    display: none;
  }
}