:root{
  --purple: #252262;
  --green: #B8EA14;
}

.line_container{
  width: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
  gap: 24px;
}

.card_line{
  height: 96% !important;
  width: 15px;
  background-color: var(--green);
  border-radius: 16px;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.point{
  height: 30px;
  width: 30px;
  margin: 35px 0px;
  background-color: white;
  border-radius: 50px;
  z-index: 1;
}

@media screen and (min-width: 450px) {
  .line_container{
    display: none;
  }
}