.how-work-container{
  padding-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 10px;
}

.how-work-info{
  height: 100%;
  width: 70vw;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.how-work-title{
  height: 40px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
}

.how-work-info div{
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-work-info li{
  height: auto;
  width: 95%;
  margin: 15px 0;
  padding: 0 0 0 15px;
  font-size: 18px;
  text-align: justify;
  list-style: none;
  display: flex;
  align-items: center;
}

.how-work-icon{
  height: 47px;
  width: 47px;
  margin: 15px 0;
  padding: 5px;
  border-radius: 50%;

  background: rgb(225,247,153);
  background: linear-gradient(90deg, rgba(225,247,153,1) 0%, rgba(148,255,239,1) 100%);
}

.how-work-description-container{
  height: auto;
  width: 70vw;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.how-work-description{
  height: auto;
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.how-work-description h4{
  text-align: center;
  font-size: 20px;
}

.how-work-description p{
  text-align: justify;
  font-size: 18px;
}

.how-work-cards-container{
  height: auto;
  width: 90%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 48px;
  align-items: center;
}

.how-work-cards{
  height: 200px;
  width: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: white;
}

.how-work-cards h5{
  font-size: 15px;
  text-align: center;
}

.how-work-cards p{
  font-size: 15px;
  text-align: center;
}

.background-logo{
  height: 500px;
  width: auto;
  position: absolute;
  z-index: -1;
  opacity: 0.1;
}

.up-logo{
  top: -70px;
  right: -70px;
}

.down-logo{
  bottom: 0;
  left: -70px;
}

@media screen and (max-width: 820px) {
  .how-work-cards-container{
    flex-wrap: wrap;
    gap: 16px;
  }
}